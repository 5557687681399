/* eslint @next/next/no-img-element: 0 */
import { get, post } from '../src/api'
import { login } from '../src/session'
import { useEffect, useState } from "react"
import { sendMessage } from '@/src/useMessage'
import LoadingSpinner from './LoadingSpinner'
import { useAppRouter } from '@/src/useAppRouter'
import Link from 'next/link'
import Modal from '@/components/Modal'
import useDeviceInfo from '@/src/useDeviceInfo'


export default function LoginScreen ({loginKey, defaultEmail}) {

  const router = useAppRouter()
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState(defaultEmail || '')
  const [error, setError] = useState('')
  const [formState, setFormState] = useState('email')

  const {mac, safari} = useDeviceInfo()
  const preventAutoComplete = !!(mac && safari)

  let signupUrl = `/signup`
  if (email) signupUrl += `?email=${email}`

  useEffect(() => {
    if (loginKey) {
      loginWithKey()
    } else {
      loginWithExistingSession()
    }

    async function loginWithKey () {
      try {
        const {redirect} = await login({loginKey})
        router.push(redirect || '/')
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    async function loginWithExistingSession () {
      try {
        const res = await get('/auth/isLoggedIn')
        const {user, redirect} = await res.json()
        if (user) {
          router.push(redirect || '/')
        } else {
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
      }
    }
  }, [loginKey, router])


  function emailChanged (e) {
    const value = e.currentTarget.value
    setEmail(value)
    setError('')
  }


  function emailSubmitted (e) {
    e.preventDefault()
    setError('')
    submit()

    async function submit() {
      sendMessage('loading', true)
      const res = await post('/auth/sendEmail', {email})

      if (res.status === 200) {
        try {
          localStorage.setItem('email', email)
          const data = await res.json()
          if (data.loginId) {
            localStorage.setItem('loginId', data.loginId)
          }
        } catch (err) {
          console.error(err)
        }
        if (window.location.hostname !== 'localhost') {
          // Add a fake delay to give the email more time to land in their inbox
          await new Promise(resolve => setTimeout(resolve, 2000))
        }
        router.push({pathname: '/email-sent'})
      } else if (res.status === 404) {
        setFormState('not found')
      } else if (res.status === 422) {
        setError((await res.json()).message)
      } else {
        setError(`Unexpected Error (status: ${res.status})`)
      }
      sendMessage('loading', false)
    }
  }


  if (loading) {
    return <LoadingSpinner size="medium" padded/>
  }


  return <>
    <Modal visible={true} noBackground>
      <div className="content p-5">
        <div style={{minWidth: 320}}>
          <div>
            <div className="content center">

              { /* if */ formState === 'email' &&
                <>

                <div className='mb-2'>
                  <img src="/img/logo.svg" alt="Social Jazz" style={{height: 64}}/>
                </div>
                  { preventAutoComplete &&
                    <form method="POST" onSubmit={e => e.preventDefault()} className="fixed top left opacity-1 overflow-hidden" style={{width: 0}}>
                      <label>Fake Form to Stop Safari TouchID Prompt</label>
                      <input disabled type="email" name="email" value="" />
                      <input disabled type="password" name="password" value="" />
                      <button type="submit">Submit</button>
                      {/*
                        This hidden (fake) login form is needed so that Safari
                        won't pop up the annoying TouchID autofill prompt on our
                        email-only login form :P
                      */}
                    </form>
                  }

                  <form method="POST" onSubmit={emailSubmitted}>
                    <div className="field left">
                      <label className=' m-1px' htmlFor="email">
                        Email <span className='gray hidden'>(or Cell Phone)</span>
                      </label>
                      <div className="control my-1">
                        <input
                          id="email"
                          className="input"
                          type="text"
                          inputMode="email"
                          name="email"
                          placeholder="you@example.com"
                          value={email}
                          onInput={emailChanged}
                          autoComplete={preventAutoComplete ? "" : "email"}
                          autoCapitalize="off"
                          autoFocus
                        />
                      </div>
                    </div>

                    <div className="buttons">
                      <button className="button is-primary">
                        Log In
                      </button>
                    </div>
                  </form>

                  <div className="error-container">
                    { /* if */ !!error &&
                      <div className="error-message">
                        {error}
                      </div>
                    }
                  </div>

                  <p className="note text-center mt-5 gray">
                    Don&apos;t have an account yet?&nbsp;
                    <Link href={signupUrl}>
                      <a className="subtle-link">Sign up</a>
                    </Link>
                  </p>

                </>
              }

              { /* if */ formState === 'not found' &&
                <div className='fit-content text-center margin-auto'>
                  <h1>🤔 Email Not Found</h1>

                  <p className='mb-1'>
                    We couldn&apos;t find an account for:
                  </p>
                  <p className='mt-1 forcewrap'>
                    <b>{email}</b>
                  </p>

                  <div>
                    <a className='button is-primary' onClick={() => setFormState('email')}>
                      Try a Different Email
                    </a>
                  </div>

                  <p className='note'>
                    or <Link href={signupUrl}>
                      <a className="link">create a new account</a>
                    </Link>
                  </p>

                </div>
              }

            </div>

          </div>
        </div>
      </div>
    </Modal>



    <style jsx>{`
      h1 {
        margin-bottom: 1.5rem;
      }
      section {
        text-align: center;
        margin: auto;
      }
      .buttons {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      button {
        width: 100%;
      }
      .error-container {
        text-align: center;
      }
      .error-container a {
        text-decoration: underline;
      }
      .error-message {
        padding: 0.5rem;
        margin: 0.5rem 0;
        background-color: rgba(254 226 226 / 0.5);
        color: red;
        display: block;
      }
    `}</style>
  </>

}
